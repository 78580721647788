<template>
  <div>
    <!-- asset section -->
    <div
      v-for="(data, index) in reportData.dataForExportPdfAsset"
      :key="`asset-section-${index}`"
    >
      <PdfContentHeader
        v-if="index % pdfOrientationLimit === 0"
        :dataProp="reportData"
        :pageProp="index / pdfOrientationLimit + 1"
        :reportDisplaySettingProp="reportDisplaySetting"
        :displaySummaryAccountProp="displaySummaryAccount"
        :totalPageCountProp="totalPageCount"
      ></PdfContentHeader>
      <PdfContentBody
        :dataProp="data"
        :reportDisplaySettingProp="reportDisplaySetting"
        :displaySummaryAccountProp="displaySummaryAccount"
      ></PdfContentBody>
      <div
        v-if="index % pdfOrientationLimit === pdfOrientationLimit - 1"
        class="html2pdf__page-break"
      ></div>
    </div>
    <!-- page break -->
    <div class="html2pdf__page-break"></div>
    <!-- another section -->
    <div
      v-for="(data, index) in reportData.dataForExportPdfAnother"
      :key="`left-section-${index}`"
    >
      <PdfContentHeader
        v-if="index % pdfOrientationLimit === 0"
        :dataProp="reportData"
        :pageProp="index / pdfOrientationLimit + 1"
        :reportDisplaySettingProp="reportDisplaySetting"
        :displaySummaryAccountProp="displaySummaryAccount"
        :totalPageCountProp="totalPageCount"
        :pageStartProp="totalPageCountAsset"
      ></PdfContentHeader>
      <PdfContentBody
        :dataProp="data"
        :reportDisplaySettingProp="reportDisplaySetting"
        :displaySummaryAccountProp="displaySummaryAccount"
      ></PdfContentBody>
      <div
        v-if="index % pdfOrientationLimit === pdfOrientationLimit - 1"
        class="html2pdf__page-break"
      ></div>
    </div>
  </div>
</template>

<script>
import PdfContentHeader from './PdfContentHeader.vue'
import PdfContentBody from './PdfContentBody.vue'
import PdfContentFooter from './PdfContentFooter.vue'

export default {
  props: {
    dataProp: Object,
    reportDisplaySettingProp: Object,
    displaySummaryAccountProp: Boolean
  },
  components: {
    PdfContentHeader,
    PdfContentBody,
    PdfContentFooter
  },
  data() {
    return {
      limit: 36,
      reportData: {
        date: null,
        companyName: null,
        toDate: null,
        accountMonth: null,
        assetData: [],
        totalAssetBalance: null,
        liabilityData: [],
        totalLiabilityBalance: null,
        equityData: [],
        totalProfitAndLossBalance: null,
        totalEquityBalance: null,
        totalLiabilityAndEquityBalance: null,
        // asset section
        dataForExportPdfAsset: [],
        // another section
        dataForExportPdfAnother: []
      },
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      displaySummaryAccount: false
    }
  },
  computed: {
    pdfOrientationLimit() {
      if (this.reportDisplaySetting.pdfOrientation === 'portrait') {
        return this.limit
      } else {
        return this.limit - 12
      }
    },
    totalPageCount() {
      const assetPagesCount = Math.ceil(
        this.reportData.dataForExportPdfAsset.length / this.pdfOrientationLimit
      )
      const anotherPagesCount = Math.ceil(
        this.reportData.dataForExportPdfAnother.length /
          this.pdfOrientationLimit
      )
      return assetPagesCount + anotherPagesCount
    },
    totalPageCountAsset() {
      const assetPagesCount = Math.ceil(
        this.reportData.dataForExportPdfAsset.length / this.pdfOrientationLimit
      )
      return assetPagesCount
    }
  },
  watch: {
    dataProp() {
      this.reportData = { ...this.dataProp }
      this.reportData.dataForExportPdfAsset = this.generateDataForExportPdf(
        'ASSET',
        this.dataProp
      )
      this.reportData.dataForExportPdfAnother = this.generateDataForExportPdf(
        'ANOTHER',
        this.dataProp
      )
    },
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    },
    displaySummaryAccountProp() {
      this.assignDataFromProp('displaySummaryAccountProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
    this.assignDataFromProp('displaySummaryAccountProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
        case 'displaySummaryAccountProp':
          this.displaySummaryAccount = this.displaySummaryAccountProp
          break
      }
    },
    generateDataForExportPdf(type, payload) {
      switch (type) {
        case 'ASSET':
          return [
            // asset
            ...payload.assetData.map(data => ({
              type: 'detail-data-asset',
              ...data
            })),
            {
              type: 'summary-data-asset',
              title: 'รวมสินทรัพย์',
              balance: payload.totalAssetBalance,
              previousBalance: payload.totalAssetPreviousBalance
            }
          ]
        case 'ANOTHER':
          return [
            // liability
            ...payload.liabilityData.map(data => ({
              type: 'detail-data-liability',
              ...data
            })),
            {
              type: 'summary-data-liability',
              title: 'รวมหนี้สิน',
              balance: payload.totalLiabilityBalance,
              previousBalance: payload.totalLiabilityPreviousBalance
            },
            // equity
            ...payload.equityData.map(data => ({
              type: 'detail-data-equity',
              ...data
            })),
            // summary
            {
              type: 'summary-data-profit-and-loss-balance',
              title: 'รวมกำไร(ขาดทุน)',
              balance: payload.totalProfitAndLossBalance,
              previousBalance: payload.totalProfitAndLossPreviousBalance
            },
            {
              type: 'summary-data-equity-balance',
              title: 'รวมส่วนของเจ้าของ',
              balance: payload.totalEquityBalance,
              previousBalance: payload.totalEquityPreviousBalance
            },
            {
              type: 'summary-data-liability-and-equity-balance',
              title: 'รวมหนี้สินและส่วนของเจ้าของ',
              balance: payload.totalLiabilityAndEquityBalance,
              previousBalance: payload.totalLiabilityAndEquityPreviousBalance
            }
          ]
        default:
          return []
      }
    }
  }
}
</script>

<style></style>
