<template>
  <div>
    <!-- row ข้อมูล รายละเอียด -->
    <div
      v-if="
        dataObj.type === 'detail-data-asset' ||
          dataObj.type === 'detail-data-liability' ||
          dataObj.type === 'detail-data-equity'
      "
      :class="
        `text-sm d-flex ${
          dataObj.accountType === 'summary' ? 'font-weight-bold' : ''
        }`
      "
    >
      <!-- รหัสบัญชี -->
      <div :class="`account-number-col-${reportDisplaySetting.pdfOrientation}`">
        <span>{{ dataObj.accountNumber }}</span>
      </div>
      <!-- ชื่อบัญชี -->
      <div :class="`account-name-col-${reportDisplaySetting.pdfOrientation}`">
        <span>
          {{ dataObj.accountName ? addIndent(`${dataObj.accountName}`) : '' }}
        </span>
      </div>
      <!-- สรุปงวดนี้ -->
      <div
        v-if="displaySummaryAccountProp"
        :class="
          `summary-balance-col-${reportDisplaySetting.pdfOrientation} text-right`
        "
      >
        <span>{{
          dataObj.summaryBalance
            ? +dataObj.summaryBalance >= 0
              ? mxNumeralThousandSeperate(dataObj.summaryBalance)
              : `(${mxNumeralThousandSeperate(dataObj.summaryBalance * -1)})`
            : null
        }}</span>
      </div>
      <!-- งวดที่แล้ว -->
      <div
        v-else
        :class="
          `previous-balance-col-${reportDisplaySetting.pdfOrientation} text-right`
        "
      >
        <span>{{
          dataObj.previousBalance
            ? +dataObj.previousBalance >= 0
              ? mxNumeralThousandSeperate(dataObj.previousBalance)
              : `(${mxNumeralThousandSeperate(dataObj.previousBalance * -1)})`
            : null
        }}</span>
      </div>
      <!-- งวดนี้ -->
      <div
        :class="
          `current-balance-col-${reportDisplaySetting.pdfOrientation} text-right`
        "
      >
        <span>{{
          dataObj.currentBalance
            ? +dataObj.currentBalance >= 0
              ? mxNumeralThousandSeperate(dataObj.currentBalance)
              : `(${mxNumeralThousandSeperate(dataObj.currentBalance * -1)})`
            : null
        }}</span>
      </div>
    </div>
    <!-- row ข้อมูล สรุป -->
    <div
      v-if="
        dataObj.type === 'summary-data-asset' ||
          dataObj.type === 'summary-data-liability' ||
          dataObj.type === 'summary-data-profit-and-loss-balance' ||
          dataObj.type === 'summary-data-equity-balance' ||
          dataObj.type === 'summary-data-liability-and-equity-balance'
      "
      class="text-sm"
    >
      <!-- เส้นด้านบนของช่องยอดเงิน -->
      <div v-if="displaySummaryAccountProp" class="d-flex">
        <div
          :class="`summary-title-col-${reportDisplaySetting.pdfOrientation}`"
        ></div>
        <div
          :class="
            `summary-balance-col-${
              reportDisplaySetting.pdfOrientation
            } summary-data-border-top ${
              dataObj.type === 'summary-data-asset'
                ? 'mt-2 mb-2'
                : dataObj.type === 'summary-data-liability'
                ? 'mt-2 mb-2'
                : dataObj.type === 'summary-data-profit-and-loss-balance'
                ? 'mt-4 mb-2'
                : dataObj.type === 'summary-data-equity-balance'
                ? 'mt-2 mb-2'
                : dataObj.type === 'summary-data-liability-and-equity-balance'
                ? 'mt-2 mb-2'
                : null
            }`
          "
        ></div>
      </div>
      <div v-else class="d-flex">
        <div
          :class="
            `summary-title-col-have-previous-${reportDisplaySetting.pdfOrientation}`
          "
        ></div>
        <div
          :class="
            `summary-balance-col-have-previous-${
              reportDisplaySetting.pdfOrientation
            } summary-data-border-top ${
              dataObj.type === 'summary-data-asset'
                ? 'mt-2 mb-2'
                : dataObj.type === 'summary-data-liability'
                ? 'mt-2 mb-2'
                : dataObj.type === 'summary-data-profit-and-loss-balance'
                ? 'mt-4 mb-2'
                : dataObj.type === 'summary-data-equity-balance'
                ? 'mt-2 mb-2'
                : dataObj.type === 'summary-data-liability-and-equity-balance'
                ? 'mt-2 mb-2'
                : null
            }`
          "
        ></div>
        <div
          :class="
            `summary-balance-col-have-previous-${
              reportDisplaySetting.pdfOrientation
            } summary-data-border-top ${
              dataObj.type === 'summary-data-asset'
                ? 'mt-2 mb-2'
                : dataObj.type === 'summary-data-liability'
                ? 'mt-2 mb-2'
                : dataObj.type === 'summary-data-profit-and-loss-balance'
                ? 'mt-4 mb-2'
                : dataObj.type === 'summary-data-equity-balance'
                ? 'mt-2 mb-2'
                : dataObj.type === 'summary-data-liability-and-equity-balance'
                ? 'mt-2 mb-2'
                : null
            }`
          "
        ></div>
      </div>

      <!-- แสดงตัวเลข -->
      <div v-if="displaySummaryAccountProp" class="d-flex">
        <!-- หัวข้อ -->
        <div
          :class="
            `summary-title-col-${reportDisplaySetting.pdfOrientation} text-right pr-3`
          "
        >
          <span>{{ dataObj.title }}</span>
        </div>
        <!-- ยอดเงิน -->
        <div
          :class="
            `summary-balance-col-${reportDisplaySetting.pdfOrientation} text-right`
          "
        >
          <span>
            {{
              +dataObj.balance >= 0
                ? mxNumeralThousandSeperate(dataObj.balance)
                : `(${mxNumeralThousandSeperate(dataObj.balance * -1)})`
            }}
          </span>
        </div>
      </div>
      <div v-else class="d-flex">
        <!-- หัวข้อ -->
        <div
          :class="
            `summary-title-col-have-previous-${reportDisplaySetting.pdfOrientation} text-right pr-3`
          "
        >
          <span>{{ dataObj.title }}</span>
        </div>
        <!-- ยอดเงิน งวดที่แล้ว -->
        <div
          :class="
            `summary-balance-col-have-previous-${reportDisplaySetting.pdfOrientation} text-right`
          "
        >
          <span>
            {{
              +dataObj.balance >= 0
                ? mxNumeralThousandSeperate(dataObj.previousBalance)
                : `(${mxNumeralThousandSeperate(dataObj.previousBalance * -1)})`
            }}
          </span>
        </div>
        <!-- ยอดเงิน งวดปัจจุบัน -->
        <div
          :class="
            `summary-balance-col-have-previous-${reportDisplaySetting.pdfOrientation} text-right`
          "
        >
          <span>
            {{
              +dataObj.balance >= 0
                ? mxNumeralThousandSeperate(dataObj.balance)
                : `(${mxNumeralThousandSeperate(dataObj.balance * -1)})`
            }}
          </span>
        </div>
      </div>

      <!-- เส้นด้านล่างของช่องยอดเงิน -->
      <div v-if="displaySummaryAccountProp">
        <div
          v-if="
            [
              'summary-data-asset',
              'summary-data-liability',
              'summary-data-liability-and-equity-balance'
            ].includes(dataObj.type)
          "
          class="d-flex"
        >
          <div
            :class="`summary-title-col-${reportDisplaySetting.pdfOrientation}`"
          ></div>
          <div
            :class="
              `summary-balance-col-${
                reportDisplaySetting.pdfOrientation
              } summary-data-border-bottom ${
                dataObj.type === 'summary-data-asset'
                  ? 'mt-2 mb-4'
                  : dataObj.type === 'summary-data-liability'
                  ? 'mt-2 mb-4'
                  : dataObj.type === 'summary-data-liability-and-equity-balance'
                  ? 'mt-2'
                  : null
              }`
            "
          ></div>
        </div>
      </div>
      <div v-else>
        <div
          v-if="
            [
              'summary-data-asset',
              'summary-data-liability',
              'summary-data-liability-and-equity-balance'
            ].includes(dataObj.type)
          "
          class="d-flex"
        >
          <div
            :class="
              `summary-title-col-have-previous-${reportDisplaySetting.pdfOrientation}`
            "
          ></div>
          <div
            :class="
              `summary-balance-col-have-previous-${
                reportDisplaySetting.pdfOrientation
              } summary-data-border-bottom ${
                dataObj.type === 'summary-data-asset'
                  ? 'mt-2 mb-4'
                  : dataObj.type === 'summary-data-liability'
                  ? 'mt-2 mb-4'
                  : dataObj.type === 'summary-data-liability-and-equity-balance'
                  ? 'mt-2'
                  : null
              }`
            "
          ></div>
          <div
            :class="
              `summary-balance-col-have-previous-${
                reportDisplaySetting.pdfOrientation
              } summary-data-border-bottom ${
                dataObj.type === 'summary-data-asset'
                  ? 'mt-2 mb-4'
                  : dataObj.type === 'summary-data-liability'
                  ? 'mt-2 mb-4'
                  : dataObj.type === 'summary-data-liability-and-equity-balance'
                  ? 'mt-2'
                  : null
              }`
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'

import { numberMixins } from '@/mixins'

export default {
  mixins: [numberMixins],
  props: {
    dataProp: Object,
    reportDisplaySettingProp: Object,
    displaySummaryAccountProp: Boolean
  },
  data() {
    return {
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      dataObj: {
        accountName: null,
        accountNumber: null,
        accountType: null,
        accountLevel: null,
        currentBalance: null,
        previousBalance: null,
        summaryBalance: null,
        type: null
      }
    }
  },
  watch: {
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    },
    dataProp() {
      this.assignDataFromProp('dataProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
    this.assignDataFromProp('dataProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
        case 'dataProp':
          this.dataObj = { ...this.dataProp }
          break
      }
    },
    addIndent(word) {
      if (this.displaySummaryAccountProp === false) {
        return word
      } else {
        const { accountLevel } = this.dataProp
        const indent =
          (accountLevel - 1) * this.reportDisplaySetting.summaryAccountIndent
        const newWord =
          lodash
            .times(indent)
            .map(() => '\xa0')
            .join('') + word
        return newWord
      }
    }
  }
}
</script>

<style scoped>
/* Portrait */
.account-number-col-portrait {
  width: 16%;
}
.account-name-col-portrait {
  width: 44%;
  white-space: nowrap;
  overflow: hidden;
}
.summary-balance-col-portrait {
  width: 20%;
}
.previous-balance-col-portrait {
  width: 20%;
}
.current-balance-col-portrait {
  width: 20%;
}

/* Landscape */
.account-number-col-landscape {
  width: 16%;
}
.account-name-col-landscape {
  width: 54%;
  white-space: nowrap;
  overflow: hidden;
}
.summary-balance-col-landscape {
  width: 15%;
}
.previous-balance-col-landscape {
  width: 15%;
}
.current-balance-col-landscape {
  width: 15%;
}

/* Summary data */
.summary-title-col-portrait {
  width: 80%;
}
.summary-balance-col-portrait {
  width: 20%;
}
.summary-title-col-have-previous-portrait {
  width: 60%;
}
.summary-balance-col-have-previous-portrait {
  width: 20%;
}
.summary-title-col-landscape {
  width: 80%;
}
.summary-balance-col-landscape {
  width: 20%;
}
.summary-title-col-have-previous-landscape {
  width: 60%;
}
.summary-balance-col-have-previous-landscape {
  width: 20%;
}
.summary-data-border-top {
  border-top: 1px solid black;
}
.summary-data-border-bottom {
  border-bottom: 1px solid black;
}
</style>
