var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.dataObj.type === 'detail-data-asset' ||
        _vm.dataObj.type === 'detail-data-liability' ||
        _vm.dataObj.type === 'detail-data-equity'
    )?_c('div',{class:`text-sm d-flex ${
        _vm.dataObj.accountType === 'summary' ? 'font-weight-bold' : ''
      }`},[_c('div',{class:`account-number-col-${_vm.reportDisplaySetting.pdfOrientation}`},[_c('span',[_vm._v(_vm._s(_vm.dataObj.accountNumber))])]),_c('div',{class:`account-name-col-${_vm.reportDisplaySetting.pdfOrientation}`},[_c('span',[_vm._v(" "+_vm._s(_vm.dataObj.accountName ? _vm.addIndent(`${_vm.dataObj.accountName}`) : '')+" ")])]),(_vm.displaySummaryAccountProp)?_c('div',{class:`summary-balance-col-${_vm.reportDisplaySetting.pdfOrientation} text-right`},[_c('span',[_vm._v(_vm._s(_vm.dataObj.summaryBalance ? +_vm.dataObj.summaryBalance >= 0 ? _vm.mxNumeralThousandSeperate(_vm.dataObj.summaryBalance) : `(${_vm.mxNumeralThousandSeperate(_vm.dataObj.summaryBalance * -1)})` : null))])]):_c('div',{class:`previous-balance-col-${_vm.reportDisplaySetting.pdfOrientation} text-right`},[_c('span',[_vm._v(_vm._s(_vm.dataObj.previousBalance ? +_vm.dataObj.previousBalance >= 0 ? _vm.mxNumeralThousandSeperate(_vm.dataObj.previousBalance) : `(${_vm.mxNumeralThousandSeperate(_vm.dataObj.previousBalance * -1)})` : null))])]),_c('div',{class:`current-balance-col-${_vm.reportDisplaySetting.pdfOrientation} text-right`},[_c('span',[_vm._v(_vm._s(_vm.dataObj.currentBalance ? +_vm.dataObj.currentBalance >= 0 ? _vm.mxNumeralThousandSeperate(_vm.dataObj.currentBalance) : `(${_vm.mxNumeralThousandSeperate(_vm.dataObj.currentBalance * -1)})` : null))])])]):_vm._e(),(
      _vm.dataObj.type === 'summary-data-asset' ||
        _vm.dataObj.type === 'summary-data-liability' ||
        _vm.dataObj.type === 'summary-data-profit-and-loss-balance' ||
        _vm.dataObj.type === 'summary-data-equity-balance' ||
        _vm.dataObj.type === 'summary-data-liability-and-equity-balance'
    )?_c('div',{staticClass:"text-sm"},[(_vm.displaySummaryAccountProp)?_c('div',{staticClass:"d-flex"},[_c('div',{class:`summary-title-col-${_vm.reportDisplaySetting.pdfOrientation}`}),_c('div',{class:`summary-balance-col-${
            _vm.reportDisplaySetting.pdfOrientation
          } summary-data-border-top ${
            _vm.dataObj.type === 'summary-data-asset'
              ? 'mt-2 mb-2'
              : _vm.dataObj.type === 'summary-data-liability'
              ? 'mt-2 mb-2'
              : _vm.dataObj.type === 'summary-data-profit-and-loss-balance'
              ? 'mt-4 mb-2'
              : _vm.dataObj.type === 'summary-data-equity-balance'
              ? 'mt-2 mb-2'
              : _vm.dataObj.type === 'summary-data-liability-and-equity-balance'
              ? 'mt-2 mb-2'
              : null
          }`})]):_c('div',{staticClass:"d-flex"},[_c('div',{class:`summary-title-col-have-previous-${_vm.reportDisplaySetting.pdfOrientation}`}),_c('div',{class:`summary-balance-col-have-previous-${
            _vm.reportDisplaySetting.pdfOrientation
          } summary-data-border-top ${
            _vm.dataObj.type === 'summary-data-asset'
              ? 'mt-2 mb-2'
              : _vm.dataObj.type === 'summary-data-liability'
              ? 'mt-2 mb-2'
              : _vm.dataObj.type === 'summary-data-profit-and-loss-balance'
              ? 'mt-4 mb-2'
              : _vm.dataObj.type === 'summary-data-equity-balance'
              ? 'mt-2 mb-2'
              : _vm.dataObj.type === 'summary-data-liability-and-equity-balance'
              ? 'mt-2 mb-2'
              : null
          }`}),_c('div',{class:`summary-balance-col-have-previous-${
            _vm.reportDisplaySetting.pdfOrientation
          } summary-data-border-top ${
            _vm.dataObj.type === 'summary-data-asset'
              ? 'mt-2 mb-2'
              : _vm.dataObj.type === 'summary-data-liability'
              ? 'mt-2 mb-2'
              : _vm.dataObj.type === 'summary-data-profit-and-loss-balance'
              ? 'mt-4 mb-2'
              : _vm.dataObj.type === 'summary-data-equity-balance'
              ? 'mt-2 mb-2'
              : _vm.dataObj.type === 'summary-data-liability-and-equity-balance'
              ? 'mt-2 mb-2'
              : null
          }`})]),(_vm.displaySummaryAccountProp)?_c('div',{staticClass:"d-flex"},[_c('div',{class:`summary-title-col-${_vm.reportDisplaySetting.pdfOrientation} text-right pr-3`},[_c('span',[_vm._v(_vm._s(_vm.dataObj.title))])]),_c('div',{class:`summary-balance-col-${_vm.reportDisplaySetting.pdfOrientation} text-right`},[_c('span',[_vm._v(" "+_vm._s(+_vm.dataObj.balance >= 0 ? _vm.mxNumeralThousandSeperate(_vm.dataObj.balance) : `(${_vm.mxNumeralThousandSeperate(_vm.dataObj.balance * -1)})`)+" ")])])]):_c('div',{staticClass:"d-flex"},[_c('div',{class:`summary-title-col-have-previous-${_vm.reportDisplaySetting.pdfOrientation} text-right pr-3`},[_c('span',[_vm._v(_vm._s(_vm.dataObj.title))])]),_c('div',{class:`summary-balance-col-have-previous-${_vm.reportDisplaySetting.pdfOrientation} text-right`},[_c('span',[_vm._v(" "+_vm._s(+_vm.dataObj.balance >= 0 ? _vm.mxNumeralThousandSeperate(_vm.dataObj.previousBalance) : `(${_vm.mxNumeralThousandSeperate(_vm.dataObj.previousBalance * -1)})`)+" ")])]),_c('div',{class:`summary-balance-col-have-previous-${_vm.reportDisplaySetting.pdfOrientation} text-right`},[_c('span',[_vm._v(" "+_vm._s(+_vm.dataObj.balance >= 0 ? _vm.mxNumeralThousandSeperate(_vm.dataObj.balance) : `(${_vm.mxNumeralThousandSeperate(_vm.dataObj.balance * -1)})`)+" ")])])]),(_vm.displaySummaryAccountProp)?_c('div',[(
          [
            'summary-data-asset',
            'summary-data-liability',
            'summary-data-liability-and-equity-balance'
          ].includes(_vm.dataObj.type)
        )?_c('div',{staticClass:"d-flex"},[_c('div',{class:`summary-title-col-${_vm.reportDisplaySetting.pdfOrientation}`}),_c('div',{class:`summary-balance-col-${
              _vm.reportDisplaySetting.pdfOrientation
            } summary-data-border-bottom ${
              _vm.dataObj.type === 'summary-data-asset'
                ? 'mt-2 mb-4'
                : _vm.dataObj.type === 'summary-data-liability'
                ? 'mt-2 mb-4'
                : _vm.dataObj.type === 'summary-data-liability-and-equity-balance'
                ? 'mt-2'
                : null
            }`})]):_vm._e()]):_c('div',[(
          [
            'summary-data-asset',
            'summary-data-liability',
            'summary-data-liability-and-equity-balance'
          ].includes(_vm.dataObj.type)
        )?_c('div',{staticClass:"d-flex"},[_c('div',{class:`summary-title-col-have-previous-${_vm.reportDisplaySetting.pdfOrientation}`}),_c('div',{class:`summary-balance-col-have-previous-${
              _vm.reportDisplaySetting.pdfOrientation
            } summary-data-border-bottom ${
              _vm.dataObj.type === 'summary-data-asset'
                ? 'mt-2 mb-4'
                : _vm.dataObj.type === 'summary-data-liability'
                ? 'mt-2 mb-4'
                : _vm.dataObj.type === 'summary-data-liability-and-equity-balance'
                ? 'mt-2'
                : null
            }`}),_c('div',{class:`summary-balance-col-have-previous-${
              _vm.reportDisplaySetting.pdfOrientation
            } summary-data-border-bottom ${
              _vm.dataObj.type === 'summary-data-asset'
                ? 'mt-2 mb-4'
                : _vm.dataObj.type === 'summary-data-liability'
                ? 'mt-2 mb-4'
                : _vm.dataObj.type === 'summary-data-liability-and-equity-balance'
                ? 'mt-2'
                : null
            }`})]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }