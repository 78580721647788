<template>
  <div class="text-sm">
    <!-- company name -->
    <div class="d-flex justify-content-between">
      <div class="w-100 text-center text-md font-weight-bold">
        <span>{{ dataProp.companyName }}</span>
      </div>
    </div>

    <!-- datetime / duration / page number -->
    <div class="d-flex justify-content-between py-3">
      <div class="w-25">
        <span>{{ getDate(dataProp.date) }}</span>
        <br />
        <span>{{ getTime(dataProp.date) }} น.</span>
      </div>
      <div class="w-50 text-center">
        <span>
          {{ `งบดุล`.split('').join(' ') }}
        </span>
        <br />
        <span> เพียง {{ getThaiDatePhrase(dataProp.toDate) }} </span>
      </div>
      <div
        class="w-25 text-right d-flex align-items-center justify-content-end"
      >
        <span>
          หน้า {{ pageStartProp ? pageStartProp + pageProp : pageProp }}/{{
            Number(totalPageCountProp) > 1 ? totalPageCountProp : '1'
          }}
        </span>
      </div>
    </div>

    <!-- report title -->
    <!-- <div class="text-center my-3">
      <span>
        {{ `งบดุล`.split('').join(' ') }}
      </span>
    </div> -->

    <!-- table header -->
    <div class="d-flex">
      <div
        :class="`account-id-col-${reportDisplaySetting.pdfOrientation} table-header-border`"
      >
        <span>รหัสบัญชี</span>
      </div>
      <div
        :class="`account-name-col-${reportDisplaySetting.pdfOrientation} table-header-border`"
      >
        <span>ชื่อบัญชี</span>
      </div>
      <div
        :class="`previous-balance-col-${reportDisplaySetting.pdfOrientation} text-right table-header-border`"
      >
        <span v-if="displaySummaryAccount">สรุปงวดนี้</span>
        <span v-else>งวดที่แล้ว</span>
      </div>
      <div
        :class="`current-balance-col-${reportDisplaySetting.pdfOrientation} text-right table-header-border`"
      >
        <span>งวดนี้</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as datetimeUtils from '@/utils/datetime'

export default {
  props: {
    dataProp: Object,
    pageProp: Number,
    reportDisplaySettingProp: Object,
    displaySummaryAccountProp: Boolean,
    totalPageCountProp: Number,
    pageStartProp: Number
  },
  data() {
    return {
      reportDisplaySetting: {
        pdfOrientation: 'portrait',
        summaryAccountIndent: 3
      },
      displaySummaryAccount: false
    }
  },
  watch: {
    reportDisplaySettingProp() {
      this.assignDataFromProp('reportDisplaySettingProp')
    },
    displaySummaryAccountProp() {
      this.assignDataFromProp('displaySummaryAccountProp')
    }
  },
  created() {
    this.assignDataFromProp('reportDisplaySettingProp')
    this.assignDataFromProp('displaySummaryAccountProp')
  },
  methods: {
    assignDataFromProp(prop) {
      switch (prop) {
        case 'reportDisplaySettingProp':
          if (this.reportDisplaySettingProp) {
            this.reportDisplaySetting = { ...this.reportDisplaySettingProp }
          }
          break
        case 'displaySummaryAccountProp':
          this.displaySummaryAccount = this.displaySummaryAccountProp
          break
      }
    },
    getDate(value) {
      if (value) {
        const yymmdd = value.split('T')[0].split('-')
        return `${yymmdd[2]}/${yymmdd[1]}/${+yymmdd[0] + 543}`
      }
      return '-'
    },
    getTime(value) {
      if (value) {
        const hhmmss = value.split('T')[1].split('.')[0].split(':')
        return `${hhmmss[0]} : ${hhmmss[1]}`
      }
      return '-'
    },
    getThaiDatePhrase(time) {
      return datetimeUtils.getThaiDatePhrase(time)
    }
  }
}
</script>

<style scoped>
.table-header-border {
  border-bottom: 1px solid black;
  padding: 5px 0px 10px 0px;
  margin: 5px 2px;
}
/* Portrait */
.account-id-col-portrait {
  width: 15%;
}
.account-name-col-portrait {
  width: 45%;
}
.previous-balance-col-portrait {
  width: 20%;
}
.current-balance-col-portrait {
  width: 20%;
}
/* Landscape */
.account-id-col-landscape {
  width: 15%;
}
.account-name-col-landscape {
  width: 55%;
}
.previous-balance-col-landscape {
  width: 15%;
}
.current-balance-col-landscape {
  width: 15%;
}
</style>
